:root {
  --gold: #FFCB4B;
  --silver: #D4D6E4;
  --bronze: #FF9328;
  --over-blue: #485f76;
  --dark-blue: #003366;
  --outstanding-color: #00B9C1;
  --good-color: #49E5A6;
  --ri-color: #FFCB4B;
  --inadequate-color: #E76D93;
  --na-color: #D4D6E4;
}

html,
body,
div#root,
div.left-nav-wrap {
  height: 100%;
  background-color: #ebebeb;
}

div#root {
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.app-wrapper {
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: #ebebeb;
  overflow: hidden;
}

div.blue-wrapper {
  height: 100%;
  padding: 50px;
  background: linear-gradient(60deg, rgb(52, 49, 158), rgb(0, 179, 187));

  display: flex;
  align-items: center;
  justify-content: center;
}

/* Default font face. */
label {
  font-family: Arboria;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}

div.table-actions button {
  margin-left: 0;
}

/* Forces modal to be above UI. */
.ReactModalPortal .ReactModal__Overlay {
  z-index: 100;
}

/* Fixes modal being too wide. */
.ReactModalPortal .ReactModal__Content {
  max-width: 1200px;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

/* Common content wrapper. */
div.container__main div.content {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
}

p {
  margin-block-end: 0.5em !important;
}

div.grower {
  flex-grow: 1;
}

/* Change the default colours and text for date ranges. */
.react-daterange-picker span,
.react-daterange-picker input {
  color: var(--dark-blue) !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}
