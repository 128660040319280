body {
  margin: 0;
  font-family: "Arboria", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.recharts-wrapper text {
  text-anchor: middle;
  dominant-baseline: central;
  fill: var(--over-blue);
  font-family: "Arboria";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 10px;

  text-align: center;
  letter-spacing: 1px;
}

.pointer {
  cursor: pointer;
}
