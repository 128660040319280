header {
  height: 50px;
  padding: 0 22px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(224.93deg, #00b3bb 0%, #34319e 80.46%);

  > * {
    color: white;
    font-family: Arboria;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    flex-basis: 96px;
    text-decoration: none;
    transition: all 200ms ease-in-out;
  }

  > :not(.logo) {
    text-align: center;
  }

  > .route:not(.active) {
    color: #ffffff99;
  }

  > .route:hover {
    color: white;
  }

  > .route {
    display: inline-block;
    vertical-align: top;
    position: relative;
    text-align: center;
    padding: 10px 0 20px;
    line-height: 24px;
    min-width: 100px;
    color: #fff;

    &::before {
      transition: all 200ms ease-in-out;
      transform: translateX(-50%);
      border-radius: 100%;
      position: absolute;
      background: var(--dark-blue);
      bottom: 11px;
      height: 4px;
      content: "";
      width: 4px;
      left: 50%;
    }

    &.active::before {
      background: #ffffff;
    }
  }

  span.logo {
    flex-basis: 200px;
  }
}
